import axios from "axios";

let access_token = "";

export const setAccessToken = (token) => {
  access_token = token;
};

export const getAccessToken = () => {
  return access_token;
};

export class WebServiceClient {
  // static baseUrl = "https://prod-api-integrar.ontheappt.cloud";
  static baseUrl = "https://stage-api-integrar.ontheappt.cloud";
  // static baseUrl = "https://dev-api-integrar.ontheappt.cloud";

  static post(requestUrl, data, params, config, sendCookies = false) {
    let paramsStr = "";
    if (config && sendCookies) {
      config.withCredentials = true;
    } else if (sendCookies) {
      config = { withCredentials: true };
    }
    if (params) {
      paramsStr = "";
      for (let key in params) {
        let value = params[key];
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === "") {
          paramsStr += "?";
        } else {
          paramsStr += "&";
        }
        paramsStr += `${key}=${value}`;
      }
    }
    return axios.post(
      WebServiceClient.baseUrl + requestUrl + paramsStr,
      data,
      config
    );
  }

  static get(requestUrl, params, config, sendCookies = false) {
    let paramsStr = "";
    if (config && sendCookies) {
      config.withCredentials = true;
    } else if (sendCookies) {
      config = { withCredentials: true };
    }
    if (params) {
      paramsStr = "";
      for (let key in params) {
        let value = params[key];
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === "") {
          paramsStr += "?";
        } else {
          paramsStr += "&";
        }
        paramsStr += `${key}=${value}`;
      }
    }

    return axios.get(WebServiceClient.baseUrl + requestUrl + paramsStr, config);
  }

  static patch(requestUrl, data, params, config, sendCookies = false) {
    let paramsStr = "";
    if (config && sendCookies) {
      config.withCredentials = true;
    } else if (sendCookies) {
      config = { withCredentials: true };
    }
    if (params) {
      paramsStr = "";
      for (let key in params) {
        let value = params[key];
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }
        key = encodeURIComponent(key);
        value = encodeURIComponent(value);

        if (paramsStr === "") {
          paramsStr += "?";
        } else {
          paramsStr += "&";
        }
        paramsStr += `${key}=${value}`;
      }
    }
    return axios.patch(
      WebServiceClient.baseUrl + requestUrl + paramsStr,
      data,
      config
    );
  }
}
